.projects-wrapper {
  background-image: url('./background.jpg');
  min-height: 100vh;
  background-position: center;
}

.projects-backdrop {
  background: rgba($color: #000000, $alpha: 0.8);
  padding: 20px;
  color: white;
}

.project-item {
  margin-bottom: 50px;
}

.project-item-title {
  font-style: italic;
  text-transform: uppercase;
}

.project-item-div {
  border: 1px solid #a3956d;
  width: 30px;
  margin-bottom: 10px;
}
