@import '../assets/styles/variables';

.home-container {
  background-image: url('./background.jpg');
  background-repeat: no-repeat;
  background-color: black;
  background-size: cover;
  background-position-x: 50%;
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.home-name {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;

  h3 {
    color: #a3956d;
    font-family: 'DINNext' !important;
  }
}

.social-link {
  text-decoration: none;
  color: #a3956d;
}

.home-links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: #a3956d;
  & a,
  button {
    margin-right: 10px;
  }
}

$primary-color: red;
