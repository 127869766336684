.img-item {
  height: calc(100vw / 4) !important;
}

.backdrop {
  z-index: 11111 !important;
  background-color: rgba($color: #000000, $alpha: 0.8) !important;

  img {
    max-width: 90vw;
    max-height: 90vh;
  }
}
