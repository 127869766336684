@use '../../assets/styles/variables';

.App {
  text-align: center;
  background-color: variables.$primary-color-dark;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &-container {
    background-color: variables.$primary-color-light;
    flex-grow: 1;
  }

  .alert-box {
    justify-content: center;
  }
}
