@import url(https://db.onlinewebfonts.com/c/3403275a8aeb6fc07122a8dd7f10e0ec?family=DINNextLTW04-Light);

@font-face {
  font-family: 'DINNext';
  src: url('https://db.onlinewebfonts.com/t/3403275a8aeb6fc07122a8dd7f10e0ec.eot');
  src: url('https://db.onlinewebfonts.com/t/3403275a8aeb6fc07122a8dd7f10e0ec.eot?#iefix') format('embedded-opentype'),
    url('https://db.onlinewebfonts.com/t/3403275a8aeb6fc07122a8dd7f10e0ec.woff2') format('woff2'),
    url('https://db.onlinewebfonts.com/t/3403275a8aeb6fc07122a8dd7f10e0ec.woff') format('woff'),
    url('https://db.onlinewebfonts.com/t/3403275a8aeb6fc07122a8dd7f10e0ec.ttf') format('truetype'),
    url('https://db.onlinewebfonts.com/t/3403275a8aeb6fc07122a8dd7f10e0ec.svg#DINNextLTW04-Light') format('svg');
}
