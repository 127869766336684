@import './src/assets/styles/variables';

.header {
  z-index: 1301 !important;
  &-typo {
    flex-grow: 1;
  }
  .menu-button {
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        position: absolute;
      }
    }
  }
  &-logo {
    margin-left: 10px;
    max-height: 30px;
    cursor: pointer;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: 60px;
  background-color: $primary-color;
  min-height: 100vh;
  color: $font-color;
}
