.video-responsive {
  margin-bottom: 50px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 50vh;
  width: 100%;
}
