$assets-path: 'https://s3.eu-central-1.amazonaws.com/tulenova.com';

$primary-color: #000; //424242
$primary-color-dark: #1b1b1b;
$primary-color-light: #6d6d6d;
$secondary-color: #a3956d;
$secondary-color-dark: #c97b63;
$secondary-color-light: #ffddc1;
$font-color: #fff;
$font-color-faded: #6d6d6d;
$font-on-secondary: #000;
